import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import OrderDetail from '@wearesection/bandai-react-components/organisms/OrderDetail';
import {usePageSetting} from '~/providers/page-context.provider';
import {handleReturnDateForLocal} from '../scrolling-thumbnail-card/scrolling-thumbnail-card';
import {GetRouter, handelConvertSimple, handleRenderPrice} from '~/utils';
import {PLACEHOLDER_IMG} from '~/constants';
import moment from 'moment';
import {getCookie} from 'cookies-next';

export const handelFormatTitleStatus = (title: string) => {
  if (!title) return title;
  const newTitle = `${title}`.trim().toLocaleLowerCase() || '';
  return `${`${title}`.trim()?.[0]?.toUpperCase()}${newTitle.substring(1, newTitle.length)}`;
};

type IPropsUserOrderOrganisms = {
  order: any;
};

export default function UserOrderOrganisms(props: IPropsUserOrderOrganisms) {
  const {order} = props;
  const {locale} = GetRouter();
  const {globalData} = usePageSetting();
  const dataTranslation = globalData?.dataTranslation?.[0] ?? {};

  const [templateData, setTemplateData] = useState<any>({
    titleTabMain: '',
    titleTabContent: '',
    tabStatus: {
      title: '',
      description: '',
    },
    orderSummary: {
      titleDate: '',
      valueDate: '',
      titleOrder: '',
      valueOrder: '',
    },
    listOrderItem: [],
    titleGift: '',
    listGift: [],
    listOrderPay: [
      {
        title: '',
        note: '',
        value: '',
      },
      {
        title: '',
        note: '',
        value: '',
      },
      {
        title: '',
        note: '',
        value: '',
      },
      {
        title: '',
        note: '',
        value: '',
      },
      {
        title: '',
        note: '',
        value: '',
      },
      {
        title: '',
        note: '',
        value: '',
      },
    ],
    orderAddress: {
      title: '',
      titleBilling: '',
      contentBilling: '',
      titleShipping: '',
      contentShipping: '',
    },
    orderShipping: {
      title: '',
      titleShipping: '',
      description: '',
      value: '',
    },
    orderPayment: {
      title: '',
      titlePayment: '',
      value: '',
    },
    help: {
      title: '',
      description: '',
    },
  });
  useEffect(() => {
    if (!order) {
      return;
    }
    if (order?.currency_code === 'KRW' && `${locale}`.toLowerCase() === 'ko-kr') {
      order.currency_code = '원';
    }
    //shipping_addresses
    const shipping_addresses = order?.shipping_addresses?.length
      ? order?.shipping_addresses[0]
      : {};
    const shippingAddress = `${shipping_addresses?.first_name} ${shipping_addresses?.last_name}${
      shipping_addresses?.street_1 ? `</br>${shipping_addresses?.street_1}` : ''
    }${shipping_addresses?.street_2 ? `</br>${shipping_addresses?.street_2}` : ''}${
      shipping_addresses?.city ? `</br>${shipping_addresses?.city},` : ''
    } ${shipping_addresses?.zip ? `</br>${shipping_addresses?.zip},` : ''} ${
      shipping_addresses?.country
    }${shipping_addresses?.phone ? `</br>${shipping_addresses?.phone}` : ''}${
      shipping_addresses?.company ? `</br>${shipping_addresses?.company}` : ''
    }`;
    //billingAddress
    const billingAddress = `${order?.billing_address?.first_name} ${
      order?.billing_address?.last_name
    }${order?.billing_address?.street_1 ? `</br>${order?.billing_address?.street_1}` : ''}${
      order?.billing_address?.street_2 ? `</br>${order?.billing_address?.street_2}` : ''
    }${order?.billing_address?.city ? `</br>${order?.billing_address?.city},` : ''} ${
      order?.billing_address?.zip && shipping_addresses?.zip
        ? `</br>${order?.billing_address?.zip},`
        : ''
    } ${order?.billing_address?.country}${
      order?.billing_address?.phone ? `</br>${order?.billing_address?.phone}` : ''
    }${order?.billing_address?.company ? `</br>${order?.billing_address?.company}` : ''}`;

    const handleFormatDescription = (title?: string) => {
      if (!title || typeof title !== 'string') return title;
      const currentToken = getCookie('currentToken');
      const result = title.replace(
        '~',
        `/${currentToken === 'TWD' ? 'tw-tc' : currentToken === 'HKD' ? 'hk-tc' : locale}`
      );
      return result;
    };

    const handleFormatPriceOld = (discounts: any[], totalNew: any): string => {
      if (!discounts?.length) return totalNew;
      let total = 0;
      discounts.map((item: any) => {
        total += item?.amount * 1;
        return null;
      });
      return `${(total + totalNew * 1).toFixed(2)}`;
    };

    const handelFormatProduct = (product: any[], isGift?: boolean) => {
      const productFormat = product
        ?.filter((item: any) =>
          !isGift ? !!item?.product_options?.length : !item?.product_options?.length
        )
        ?.map((item: any) => {
          return {
            hrefImg: item?.images?.[0]?.url_thumbnail || PLACEHOLDER_IMG,
            titleGame: item?.name || '',
            amount: item?.quantity || '0',
            qty: dataTranslation['estore-order-detail-title-qty'],
            price: isGift
              ? dataTranslation['estore-cart-quantity-card-free-text'] || ''
              : `${handleRenderPrice(
                  item?.base_price,
                  handelConvertSimple(order?.currency_code || 'SGD'),
                  ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
                )}` === `${handelConvertSimple(order?.currency_code || 'SGD')}0.00`
              ? ''
              : `${handleRenderPrice(
                  item?.base_price,
                  handelConvertSimple(order?.currency_code || 'SGD'),
                  ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
                )}`,
            listPlatform: item?.product_options?.length
              ? item?.product_options?.map((plat: any) => {
                  return plat?.display_value_customer || '';
                })
              : [],
            priceOld: isGift
              ? ''
              : handleRenderPrice(
                  handleFormatPriceOld(item?.applied_discounts || [], item?.base_price || '0.00'),
                  handelConvertSimple(order?.currency_code || 'SGD'),
                  ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
                ) ===
                `${handleRenderPrice(
                  item?.base_price || '0.00',
                  handelConvertSimple(order?.currency_code || 'SGD'),
                  ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
                )}`
              ? ''
              : handelConvertSimple(order?.currency_code || 'SGD') +
                handleFormatPriceOld(item?.applied_discounts || [], item?.base_price || '0.00'),
          };
        });
      return productFormat;
    };

    const handelFormatCoupons = (coupons: any[]) => {
      let result = '';
      coupons?.map((item: any) => {
        result += item?.code + '/';
        return null;
      });

      return result.slice(0, result.length - 1);
    };

    const handelFormatValueCoupons = (coupons: any[]) => {
      let result = 0;
      coupons?.map((item: any) => {
        result += item?.discount * 1;
        return null;
      });
      return `${result.toFixed(2)}`;
    };

    const listOrderPay = (isCoupons: boolean) => {
      const Year = new Date(order?.date_created || '').getFullYear();
      const currency_code = order?.currency_code || 'SGD';
      const lengthItem =
        order?.products?.filter((item: any) => !!item?.product_options?.length)?.length || 0;
      const result = [
        {
          title: `${lengthItem} ${
            dataTranslation[
              lengthItem > 1
                ? 'estore-account-title-order-label-amount'
                : 'estore-cart-summary-card-item-text'
            ]
          }`,
          note: '',
          value: `${handleRenderPrice(
            order?.subtotal_inc_tax || '0.00',
            handelConvertSimple(order?.currency_code || 'SGD'),
            ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
          )}`,
        },
        {
          title: dataTranslation['estore-order-detail-discount'],
          note: '',
          value: `${(order?.discount_amount || 0) * 1 > 0 ? '-' : ''}${handleRenderPrice(
            order?.discount_amount || '0.00',
            handelConvertSimple(order?.currency_code || 'SGD'),
            ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
          )}`,
        },
        {
          title: `GST (${Year < 2024 ? '8' : '9'}%)`,
          note: '',
          value: `${handleRenderPrice(
            order?.total_tax || '0.00',
            handelConvertSimple(order?.currency_code || 'SGD'),
            ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
          )}`,
        },
        {
          title: dataTranslation['estore-order-detail-shipping'],
          note: `${
            order?.shipping_addresses?.length ? order?.shipping_addresses[0]?.shipping_method : ''
          }`,
          value: `${handleRenderPrice(
            order?.shipping_cost_inc_tax || '0.00',
            handelConvertSimple(order?.currency_code || 'SGD'),
            ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
          )}`,
        },
        {
          title: dataTranslation['estore-order-detail-promo-code'],
          note:
            typeof order?.coupons !== 'string'
              ? handelFormatCoupons(order?.coupons)
              : order?.coupons,
          value:
            typeof order?.coupons !== 'string'
              ? `${handleRenderPrice(
                  handelFormatValueCoupons(order?.coupons) || '0.00',
                  handelConvertSimple(order?.currency_code || 'SGD'),
                  ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
                )}`
              : `${handelConvertSimple(order?.currency_code || 'SGD')}0.00`,
        },
        {
          title: dataTranslation['estore-order-detail-total'],
          note: '',
          value: `${handleRenderPrice(
            order?.total_inc_tax || '0.00',
            handelConvertSimple(order?.currency_code || 'SGD'),
            ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
          )}`,
        },
      ];
      if (!isCoupons && currency_code === 'SGD') {
        result.splice(4, 1);
      }
      if (!isCoupons && currency_code !== 'SGD') {
        result.splice(2, 1);
        result.splice(3, 1);
      }

      return result;
    };
    const newTemplateData = {
      titleTabMain: dataTranslation['estore-order-detail-title-main'],
      titleTabContent: dataTranslation['estore-order-detail-title-summary'],
      tabStatus: {
        title:
          dataTranslation[`${order?.custom_status}`.toLowerCase()] ||
          handelFormatTitleStatus(order?.custom_status || ''),
        description: dataTranslation['estore-status-order-details-no-estimated-arrival-yet'] || '',
        trackingNo: order?.tracking_number
          ? `${dataTranslation['estore-tracking-number-label'] || ''} ${order?.tracking_number}`
          : '',
      },
      orderSummary: {
        titleDate: dataTranslation['estore-order-detail-order-date'] + ':',
        valueDate: handleReturnDateForLocal(
          locale || '',
          `${
            order?.date_created
              ? moment(order.date_created, 'ddd, DD MMM YYYY', 'en').format('YYYY-MM-DD')
              : ''
          }`.substring(0, 10)
        ),
        titleOrder: dataTranslation['estore-title-order-id-detail'] + ':',
        valueOrder: `#${order?.id}`,
      },
      listOrderItem: order?.products?.length ? handelFormatProduct(order?.products) : [],
      titleGift: dataTranslation['estore-order-detail-title-free-gift'],
      listGift: order?.products?.length ? handelFormatProduct(order?.products, true) : [],
      listOrderPay: listOrderPay(!!order?.coupons),
      orderAddress: {
        title: dataTranslation['estore-order-detail-title-address'],
        titleBilling: dataTranslation['estore-order-detail-title-billing-address'],
        contentBilling:
          shippingAddress?.toLowerCase() === billingAddress?.toLowerCase()
            ? dataTranslation['estore-order-detail-title-same-as-address']
            : billingAddress,
        titleShipping: dataTranslation['estore-order-detail-title-shipping-address'],
        contentShipping: shippingAddress,
        titleNote: dataTranslation['estore-order-detail-title-order-note'] || '',
        descriptionNote: order?.customer_message || '',
      },
      orderShipping: {
        title: dataTranslation['estore-order-detail-shipping'],
        titleShipping: `${
          order?.shipping_addresses?.length ? order?.shipping_addresses[0]?.shipping_method : ''
        }`,
        // description: `${dataTranslation['estore-order-detail-status-description']} `,
        value: `${handleRenderPrice(
          order?.shipping_cost_inc_tax || '0.00',
          handelConvertSimple(order?.currency_code || 'SGD'),
          ['HKD', 'TWD', 'KRW'].includes(order?.currency_code) ? 'tc' : locale
        )}`,
      },
      orderPayment: {
        title: dataTranslation['estore-order-detail-title-payment'],
        titlePayment: dataTranslation['estore-order-detail-title-paid-by'],
        titleIcon: order?.credit_cart?.card_type || '',
        value: order?.credit_cart?.card_last4
          ? '****' + order?.credit_cart?.card_last4
          : `${
              ['HKD', 'TWD'].includes(order?.currency_code) ? '信用卡' : order?.payment_method
            }`.split('(')?.[0] || '',
      },
      help: {
        title: dataTranslation['estore-order-detail-title-need-help'],
        description: handleFormatDescription(
          dataTranslation['estore-order-detail-title-need-help-link']
        ),
      },
    };

    setTemplateData(newTemplateData);
  }, [order, dataTranslation]);

  return (
    <Box>
      <OrderDetail {...templateData} />
    </Box>
  );
}
